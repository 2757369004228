<template>
	<div class="right_content_header">
    <span class="right_content_header_title">{{ contentHeaderTitle }}</span>
    <div class="right_content_header_user">
      <el-dropdown class="right_content_header_SettingBtn" trigger="click" @command="handleDropdownClick">
        <span class="right_content_header_SettingBtnContent">
          <img class="right_content_header_SettingBtn_image" src="@/assets/loginImages/ht_icon_shezhi.png"/>
        </span>
        <el-dropdown-menu class="right_content_header_SettingMenu" slot="dropdown">
          <el-dropdown-item
            v-for="item in settingMenuData"
            :key="item.lineCode"
            :command="item.lineCode"
          >
            {{ item.lineName }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-image class="right_content_header_userIcon" :src="userInfo.avatar" v-if="userInfo.avatar"/>
      <span class="right_content_header_userName" style="white-space: pre;" @click="userClick">{{ userInfo.nickname }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'HomeTop',
    computed: {
      ...mapGetters(['userInfo'])
    },
    props: ['contentHeaderTitle'],

    data() {
        //这里存放数据",
        return {
          userIconFit: 'fill',
          settingMenuData: [
            {
              lineName : "Setting",
              lineCode : "1",
            },
            {
              lineName : "Logout",
              lineCode : "2",
            }
          ],
          url: "",


        };
    },
    components: {  },

    methods: {
      ...mapActions(['UserOut']),
      handleDropdownClick(value) {
        if (value == '1') {
          this.$store.state.homeTopHeaderTitle = "Settings";
          this.$router.push('/settings');
        }else if (value == '2') {
          this.userInfo.avatar = '';
          this.userInfo.nickname = '';
          this.UserOut();
          this.$router.push('/');
        }
      },
      userClick() {
        console.log('userClick ~~~ '+this.userInfo.nickname);
      },
      termsOfUseClick(){
        console.log('termsOfUseClick')
      },
      privacyPolicyClick() {
        console.log('privacyPolicyClick')
      },
      contactUsClick() {
        console.log('contactUsClick')
      },
      creditsClick(){
        console.log('creditsClick')
      },
    },
}

</script>

<style lang="scss">

</style>
