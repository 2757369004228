import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/filter' // global filter
import Toast from '@/components/Toast/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';

Vue.use(ElementUI, {locale})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Vue.prototype.showSuccess = function (title) {
  Toast({message: title, type: 'success'})
}

Vue.prototype.showError = function (title) {
  Toast({message: title, type: 'warning'})
}
