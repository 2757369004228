import { axios } from '@/utils/request'
import qs from 'qs'

// 联系我们
export function addWebsiteContactus (parameter) {
  return axios({
    url: '/website/contact/contactus',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取官方场景
export function getOfficalScenes (parameter) {
  return axios({
    url: '/website/scene/officalScenes',
    method: 'get',
    params: parameter
  })
}

// 获取用户场景
export function getUserScenes (parameter) {
  return axios({
    url: '/website/scene/userScenes',
    method: 'get',
    params: parameter
  })
}

// 获取用户场景详情
export function getScenesDetail (parameter) {
  return axios({
    url: '/website/scene/sceneDetail',
    method: 'get',
    params: parameter
  })
}

// 获取推荐场景
export function getScenesRecommend (parameter) {
  return axios({
    url: '/website/scene/recommend',
    method: 'get',
    params: parameter
  })
}

// 获取热门衣服
export function getGoodsHotClothes (parameter) {
  return axios({
    url: '/website/goods/hotClothes',
    method: 'get',
    params: parameter
  })
}

// 获取衣服详情
export function getGoodsDetail (parameter) {
  return axios({
    url: '/website/goods/goodsDetail',
    method: 'get',
    params: parameter
  })
}

// 获取推荐商品
export function getGoodsRecommend (parameter) {
  return axios({
    url: '/website/goods/recommend',
    method: 'get',
    params: parameter
  })
}

// 获取商品页banner列表
export function getGoodsBanner (parameter) {
  return axios({
    url: '/website/goods/banner',
    method: 'get',
    params: parameter
  })
}

// 获取达人列表
export function getUserExpertUser (parameter) {
  return axios({
    url: '/website/user/expertUser',
    method: 'get',
    params: parameter
  })
}

// 获取达人详情
export function getUserDetail (parameter) {
  return axios({
    url: '/website/user/userDetail',
    method: 'get',
    params: parameter
  })
}

// 获取推荐达人
export function getUserRecommend (parameter) {
  return axios({
    url: '/website/user/recommend',
    method: 'get',
    params: parameter
  })
}

// 获取taient story列表
export function getUserTaientStory (parameter) {
  return axios({
    url: '/website/user/taientStory',
    method: 'get',
    params: parameter
  })
}

// 获取taient story详情
export function getUserTaientStoryDetail (parameter) {
  return axios({
    url: '/website/user/taientStoryDetail',
    method: 'get',
    params: parameter
  })
}

// 获取create-TALENT
export function getUserHotusers (parameter) {
  return axios({
    url: '/website/user/hotusers',
    method: 'get',
    params: parameter
  })
}

// 登陆二维码生成
export function getLoginQrcodeCreate (parameter) {
  return axios({
    url: '/website/user/loginQrcodeCreate',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 登陆二维码轮询检测
export function getLoginQrcodeCheck (parameter) {
  return axios({
    url: '/website/user/loginQrcodeCheck',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 用户登录
export function getUserLogin (parameter) {
  return axios({
    url: '/website/user/login',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取文章列表(树形展示)
export function getArticleTree (parameter) {
  return axios({
    url: '/website/article/treeArticles',
    method: 'get',
    params: parameter
  })
}

// 获取文章列表(分页)
export function getArticleList (parameter) {
  return axios({
    url: '/website/article/list',
    method: 'get',
    params: parameter
  })
}

// 获取文章详情
export function getArticleDetail (parameter) {
  return axios({
    url: '/website/article/detail',
    method: 'get',
    params: parameter
  })
}

// 获取用户ugc config
export function getUgcConfig (parameter) {
  return axios({
    url: '/website/ugc/config',
    method: 'get',
    params: parameter
  })
}

// 获取用户 ugc 商品
export function getUgcGoodsList (parameter) {
  return axios({
    url: '/website/ugc/goodsList',
    method: 'get',
    params: parameter
  })
}

// 获取 ugc 商品详情
export function getUgcGoodsInfo (parameter) {
  return axios({
    url: '/website/ugc/goodsInfo',
    method: 'get',
    params: parameter
  })
}

// 获取 ugc 售卖最好商品
export function getUgcBestGoods (parameter) {
  return axios({
    url: '/website/ugc/bestGoods',
    method: 'get',
    params: parameter
  })
}

// 获取ugc商品报表数据
export function getUgcGoodsSummary (parameter) {
  return axios({
    url: '/website/ugc/goodsSummary',
    method: 'get',
    params: parameter
  })
}

// 获取ugc商品销售数据
export function getUgcGoodsSalesData (parameter) {
  return axios({
    url: '/website/ugc/goodsSalesData',
    method: 'get',
    params: parameter
  })
}

// 获取ugc商品类型
export function getUgcShopGoodsTypes (parameter) {
  return axios({
    url: '/website/ugc/shopGoodsTypes',
    method: 'get',
    params: parameter
  })
}

// 用户指定UGC商品信息设置
export function goodsInfoSet (parameter) {
  return axios({
    url: '/website/ugc/goodsInfoSet',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 用户指定UGC商品设置范围
export function ugcUserGoodsPriceLimit (parameter) {
  return axios({
    url: '/website/ugc/ugcUserGoodsPriceLimit',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 批量用户UGC商品上/下架
export function ugcUserGoodsUpAndDown (parameter) {
  return axios({
    url: '/website/ugc/userGoodsUpAndDown',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 提现币信息
export function ugcCashInfo (parameter) {
  return axios({
    url: '/website/ugc/cashInfo',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 申请提现
export function ugcCashExtract (parameter) {
  return axios({
    url: '/website/ugc/cashExtract',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 申请提现记录列表
export function ugcCashExtractRecord (parameter) {
  return axios({
    url: '/website/ugc/cashExtractRecord',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 提现币转当地货币数值
export function ugcCashExtractMoney (parameter) {
  return axios({
    url: '/website/ugc/cashExtractMoney',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取用户提现信息
export function ugcCashAccountInfo (parameter) {
  return axios({
    url: '/website/ugc/cashAccountInfo',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 保存用户提现信息
export function ugcCashAccountSet (parameter) {
  return axios({
    url: '/website/ugc/cashAccountSet',
    method: 'post',
    data: qs.stringify(parameter),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取文章列表
export function geAtarticleList (parameter) {
  return axios({
    url: '/website/article/list',
    method: 'get',
    params: parameter
  })
}

