<template>
    <div class="full-page">

      <div class="left_div">
        <el-image class="left_image" :src="require('@/assets/loginImages/ht_icon_mass.png')" ></el-image>
        <div class="left_div_title">Please enter mass account</div>

        <div class="left_div_QrCode">
          <div class="left_div_QrCode_title">QR code</div>
          <div class="left_div_QrCode_reminder"></div>
          <div class="left_div_QrCode_padding"></div>
          <div class="left_div_QrCode_content">
            <img class="left_div_QrCode_content_login_img" :src="codeInfo.qcode_url" />
          </div>
          <div class="left_div_QrCode_RefreshBtn" type="primary" @click="refreshClick">
            <div class="left_div_QrCode_RefreshBtn_content">
              <el-image class="left_div_QrCode_RefreshBtn_image" :src="require('@/assets/loginImages/ht_icon_refresh.png')" ></el-image>
              <span class="left_div_QrCode_RefreshBtn_title">Refresh</span>
            </div>
          </div>
          <span class="left_div_QrCode_RefreshBtn_SpanOne">Please open mass</span>
          <span class="left_div_QrCode_RefreshBtn_SpanTwo">Scan the QR code to log in</span>

          <div class="left_div_QrCode_bottom">
            <div class="left_div_QrCode_bottom_content">
              <el-image class="left_div_QrCode_bottom_content_image" :src="require('@/assets/loginImages/ht_icon_header.png')" ></el-image>
              <span class="left_div_QrCode_bottom_content_Span">Home page</span>
              <el-image class="left_div_QrCode_bottom_content_ArrowImage" :src="require('@/assets/loginImages/icon_studio_seven_arrow_right.png')" ></el-image>
              <el-image class="left_div_QrCode_bottom_content_image" :src="require('@/assets/loginImages/ht_icon_code.png')" ></el-image>
              <span class="left_div_QrCode_bottom_content_Span">My code</span>
              <el-image class="left_div_QrCode_bottom_content_ArrowImage" :src="require('@/assets/loginImages/icon_studio_seven_arrow_right.png')" ></el-image>
              <el-image class="left_div_QrCode_bottom_content_image" :src="require('@/assets/loginImages/ht_icon_sweep.png')" ></el-image>
              <span class="left_div_QrCode_bottom_content_Span">sweep</span>
            </div>
          </div>
        </div>

        <div class="left_div_bottom">You need to register with massAPP to log in to mass Studios</div>
      </div>

      <div class="right_div">
        <el-image class="right_image" :src="require('@/assets/loginImages/ht_bg_tu.jpg')" ></el-image>
      </div>

    </div>
</template>


<script >
  import { mapGetters, mapActions } from 'vuex'
  import { getLoginQrcodeCreate, getLoginQrcodeCheck, getUserLogin } from '@/api/index'

  export default {
    name: 'Login',
    data () {
      return {
        time: 100000,
        isCheck: false,
        timer: '',
        codeInfo: {
          qcode: '',
          qcode_url: ''
        },
        loginInfo:{
        login_type: 1,
        code: '',
        token: ''
        },
        userData: {},
        appSetting: {},
      }

    },
    watch: {
      time: function (newVal) {
        if (!this.isCheck) {
          this.checkQrcode()
        }
        if (newVal === 0) {
          clearInterval(this.timer)
          this.timer = null
        }
      }
    },
    mounted () {
      // 设定⼀个定时器，每⼀秒调⽤⼀次countTime⽅法，time-1
      this.timer = setInterval(this.countTime, 2000)
    },
    computed: {
      ...mapGetters(['isMobile','v3staticUrl']),
    },

    created () {
      this.getQrcode();
    },

    methods: {
      ...mapActions(['SetUserInfo', 'SetAppSetting']),
      noTouch () {

      },
      closeDialog () {
        this.$emit('onClose', true)
      },

      refreshClick() {
        console.log('Button refreshClick!');
        this.getQrcode();
      },
      getQrcode () {
        getLoginQrcodeCreate().then(res => {
          this.codeInfo = res
          this.isCheck = false;
        }).catch(error => {
          this.isCheck = true
          console.log(error)
        });
      },
      checkQrcode () {
        getLoginQrcodeCheck({ qcode: this.codeInfo.qcode }).then(res => {
          if (res.code === 999982) {
            this.isCheck = true;
            this.getQrcode()
          }
          if(res.token){
            this.loginInfo.token = res.token
            this.loginInfo.code = res.code
            this.isCheck = true;
            this.goLogin()
          }
        });
      },
      goLogin () {
        getUserLogin(this.loginInfo).then(res => {
          if (typeof(res.user_base) !== 'undefined') {
            this.userData = res.user_base
          }
          console.log('this.userData: ' + this.userData)
          this.userData.token = res.token
          this.SetUserInfo(this.userData)

          if (typeof(res.app_setting) !== 'undefined') {
            console.log('login 111');
            this.appSetting = res.app_setting;
            console.log('login 222 ' + this.appSetting);
          }
          this.SetAppSetting(this.appSetting)

          this.$message({
            message: 'Login Successfully !',
            type: 'success'
          });

          this.$router.push('/myContents');
        });
      },
      countTime () {
        this.time--
        console.log(this.time)
      }

    }
  }
</script>

<style lang="scss">

  .full-page {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: hidden;
  }

  .right_div {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 38%;
  }

  .right_image {
    height: 100%;
    max-height: 100%;
  }

  .left_div {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    left: 0;
    height: 100%;
    width: 63%;
    background-color: white;
  }

  .left_image {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 230px;
    width: 300px;
  }

  .left_div_title {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    height: 48px;
    font-family: Gotham, Gotham;
    font-weight: normal;
    font-size: 40px;
    color: #3D3D3D;
    line-height: 48px;
    text-align: center;
  }

  .left_div_QrCode {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    height: 50%;
  }

  .left_div_QrCode_title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 29px;
    font-size: 24px;
    font-family: Gotham, Gotham;
    font-weight: normal;
    color: #3D3D3D;
    line-height: 29px;
    text-align: center;
  }

  .left_div_QrCode_reminder {
    position: absolute;
    top: 45px;
    width: 101px;
    height: 2px;
    background: #3D3D3D;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

  .left_div_QrCode_padding {
    position: absolute;
    top: 46px;
    width: 454px;
    height: 1px;
    background: #E1E1E1;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

  .left_div_QrCode_content {
    position: absolute;
    top: 65px;
    width: 182px;
  }

  .left_div_QrCode_content_login_img {
    width: 182px;
  }

  .left_div_QrCode_RefreshBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 265px;
    width: 182px;
    height: 24px;
    background-color: transparent;
    border: none;
  }

  .left_div_QrCode_RefreshBtn_content {
    display: flex;
    flex-direction: row; /* 将内容水平排列 */
    align-items: center;
  }

  .left_div_QrCode_RefreshBtn_image {
    max-width: 22px;
    max-height: 22px;
  }

  .left_div_QrCode_RefreshBtn_title {
    width: 91px;
    height: 22px;
    margin-left: 3px;
    font-size: 24px;
    font-family: Gotham, Gotham;
    color: #9E9E9E;
    line-height: 22px;
  }

  .left_div_QrCode_RefreshBtn_SpanOne {
    position: absolute;
    top: 300px;
    height: 34px;
    font-size: 28px;
    font-family: Gotham, Gotham;
    font-weight: normal;
    color: #3D3D3D;
    text-align: center;
  }

  .left_div_QrCode_RefreshBtn_SpanTwo {
    position: absolute;
    top: 345px;
    height: 34px;
    font-size: 28px;
    font-family: Gotham, Gotham;
    font-weight: normal;
    color: #3D3D3D;
    text-align: center;
  }

  .left_div_QrCode_bottom {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 400px;
    left: 0;
    right: 0;
    height: 22px;
  }

  .left_div_QrCode_bottom_content {
    display: flex;
    flex-direction: row; /* 将内容水平排列 */
    align-items: center;
  }

  .left_div_QrCode_bottom_content_image {
    height: 22px;
  }

  .left_div_QrCode_bottom_content_ArrowImage {
    margin-left: 17px;
    margin-right: 22px;
    margin-bottom: 4px;
    height: 22px;
  }

  .left_div_QrCode_bottom_content_Span {
    margin-left: 13px;
    height: 22px;
    font-size: 24px;
    font-family: Gotham, Gotham;
    color: #9E9E9E;
    line-height: 22px;
  }

  .left_div_bottom {
    position: absolute;
    top: 620px;
    left: 0;
    right: 0;
    height: 22px;
    font-family: Gotham, Gotham;
    font-weight: normal;
    font-size: 24px;
    color: #3D3D3D;
    line-height: 22px;
    text-align: center;
  }

</style>
