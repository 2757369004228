import Vue from 'vue'
import storage from '@/utils/localStorage'
import utils from '@/utils/utils'

const isMobile = utils.isMobile()
const isIPhone = utils.isIPhone()

const state = {
  basicData: {
    title: '',
    logo: '',
    company: '',
    address: '',
    record_number: '',
    copyright: ''
  },
  userInfo: {
    nickname: '',
    age: '',
    gender: '',
    token: '',
    user_id: '',
    avatar: ''
  },
  appSetting: {
    cdn_url: '',
  },
  staticUrl: window.staticUrl,
  isIPhone: isIPhone,
  isMobile: isMobile
}

const getters = {
  staticUrl: state => state.staticUrl,
  basicData: state => state.basicData,
  isMobile: state => state.isMobile,
  isIPhone: state => state.isIPhone,
  userInfo: (state) => {
    if (state.userInfo.token === '') {
      let userInfo = storage.get('userInfo')
      if (userInfo !== null) {
        state.userInfo = JSON.parse(userInfo)
      }
    }
    return state.userInfo
  },
  appSetting: (state) => {
    let appSetting = storage.get('appSetting')
    if (appSetting !== null) {
      state.appSetting = JSON.parse(appSetting)
    }
    return state.appSetting
  },
}

const mutations = {
  updateBasicData (state, params) {
    storage.set('basicData', JSON.stringify(params))
  },
  updateUserInfo (state, params) {
    storage.set('userInfo', JSON.stringify(params))
  },
  updateAppSetting (state, params) {
    storage.set('appSetting', JSON.stringify(params))
  },
}

const actions = {
  SetUserInfo ({ commit,state }, data) {
    console.log(state.userInfo)
    state.userInfo = data
    commit("updateUserInfo", data)
  },
  SetAppSetting ({ commit,state }, data) {
    state.appSetting = data
    commit('updateAppSetting', data)
  },
  UserOut ({ commit }) {
    state.userInfo.token = '';
    let userInfo = {
      username: '',
      mobile: '',
      email: '',
      token: '',
      userid: '',
    }
    commit('updateUserInfo', userInfo)

    let appSetting = {
      cdn_url: '',
    }
    commit('updateAppSetting', appSetting)
  },
  ComingSoon () {
    Vue.prototype.showError('Coming Soon ！')
    return
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
