import Vue from 'vue'
import axios from 'axios'
import { VueAxios } from './axios'
import router from '../router/index'

// 创建 axios 实例
const service = axios.create({
  // baseURL: location.protocol + process.env.VUE_APP_API_BASE_URL, // api base_url
  baseURL: window.apiUrl,
  timeout: 6000 // 请求超时时间
})

const err = (error) => {
  var errorMessage
  if (error.response) {
    errorMessage = error.response.data
  } else {
    errorMessage = error.toString()
  }
  Vue.prototype.showError(errorMessage)
  // Vue.prototype.showNotify(errorMessage, 'error', () => {});
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  // const token = Vue.ls.get(ACCESS_TOKEN)
  // if (token) {
  //   config.headers['Authorization'] = 'Bearer ' + token
  // }
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  const res = response.data
  if (res.code === 0) {
    if (typeof(res.data) !== 'undefined') {
      return res.data
    }else{
      return res
    }
  } else if (res.code === 999982 || res.code === 999981) {
    return { code: res.code }
  } else if (res.code === 999997 || res.code === 999999) {
    // 让用户重新登录
    router.push('/')
  }
  else {
    Vue.prototype.showError(res)
    return Promise.reject(res)
  }
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
