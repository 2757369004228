<template>
    <div class="left_content_full">

        <div class="left-page-header">
            <img class="header-logo" src="@/assets/loginImages/logo.png">
        </div>

        <div class="left-page-content">
            <el-menu
                default-active="1"
                class="el-menu-vertical-demo"
                text-color="#fff"
                active-text-color="#fff"
                active-background-color="#36363E"
                background-color="#232329"
                @select="contentMenuSelect"
            >
                <el-menu-item class="content-menu-item" index="1">
                    <img class="menu-item-image" src="@/assets/loginImages/ht_icon_mycontens.png">
                    <span class="menu-item-span">My contents</span>
                </el-menu-item>
                <el-menu-item class="content-menu-item" index="2">
                    <img class="menu-item-image" src="@/assets/loginImages/ht_icon_sales.png">
                    <span class="menu-item-span">Sales</span>
                </el-menu-item>
                <el-menu-item class="content-menu-item" index="3">
                    <img class="menu-item-image" src="@/assets/loginImages/ht_icon_withdraw.png">
                    <span class="menu-item-span">withdraw</span>
                </el-menu-item>
                <el-menu-item class="content-menu-item" index="4">
                    <img class="menu-item-image" src="@/assets/loginImages/ht_icon_insight.png">
                    <span class="menu-item-span">insight</span>
                </el-menu-item>
            </el-menu>
        </div>

        <div class="left-page-padding"></div>

        <div class="left-page-footer">
            <el-menu
                default-active="1"
                class="el-menu-vertical-demo"
                text-color="#fff"
                active-text-color="#fff"
                active-background-color="#36363E"
                background-color="#232329"
                @select="footerMenuSelect"
            >
                <el-menu-item class="content-menu-item" index="1">
                    <img class="menu-item-image" src="@/assets/loginImages/ht_icon_notice.png">
                    <span class="menu-item-span">NOTICE</span>
                </el-menu-item>
                <el-menu-item class="content-menu-item" index="2">
                    <img class="menu-item-image" src="@/assets/loginImages/ht_icon_withdraw.png">
                    <span class="menu-item-span">guides</span>
                </el-menu-item>
            </el-menu>
        </div>


    </div>
</template>

<script>
import { geAtarticleList } from '@/api/index'

  export default {
    name: 'HomeLeft',
    data () {
      return {
        articleID : '1',
      }
    },

    created () {

    },

    mounted() {
        this.geAtarticleList();
    },

    updated() {
      console.log('HomeLeft updated');
    },

    methods: {
        contentMenuSelect(index) {
            if (index == '1') {
              this.$store.state.homeTopHeaderTitle = 'My contents'
              this.$router.push('/myContents');
            }else if (index == '2') {
              this.$store.state.homeTopHeaderTitle = 'Sales'
              this.$router.push('/sales');
            }else if (index == '3') {
              this.$store.state.homeTopHeaderTitle = 'withdraw'
              this.$router.push('/withdraw');
            }else if (index == '4') {
              this.$store.state.homeTopHeaderTitle = 'insight'
              this.$router.push('/insight');
            }
        },
        footerMenuSelect(key) {
            // this.$emit('handleFooterMenuSelect', key);
            if (key == 1) {
                const articleURL = "https://www.massbloom.com/newDetail?id=" + this.articleID;
                window.open(articleURL, '_blank');
            }else if (key == 2) {
                window.open('https://www.massbloom.com/article?id=10', '_blank');
            }
        },

        geAtarticleList() {
            let params = {  cat_id : '5',
                            page : '1',
                            page_size : '1'
                         };
            geAtarticleList(params).then(response => {
                console.log("geAtarticleList ~~~ ", JSON.stringify(response));
            });

        },

    },
  }
</script>

<style lang="scss">

</style>
