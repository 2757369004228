<template>
    <div id="app">
      <div class="home-full-page">
        <div class="left_content">
          <home-left @contentMenuSelect="handleContentMenuSelect" @footerMenuSelect="handleFooterMenuSelect"></home-left>
        </div>
        <div class="right_content">
          <home-top :contentHeaderTitle="this.$store.state.homeTopHeaderTitle"></home-top>
          <router-view/>
          <home-footer v-show="this.$store.state.showHomeFooter"></home-footer> 
        </div>
      </div>
    </div>
  </template>

<script>
  import { mapGetters } from 'vuex'
  import { HomeFooter, HomeLeft, HomeTop } from '@/components'

  export default {
    name: 'Layout',
    components: {
      HomeTop,
      HomeFooter,
      HomeLeft
    },

    data () {
      return {

      }
    },

    computed: {
      ...mapGetters(['isMobile','staticUrl']),
    },

    created () {

    },

    updated() {

    },

    methods: {
      handleContentMenuSelect(index) {
        console.log('handleContentMenuSelect: ' + index)
      },
      handleFooterMenuSelect(index) {
        console.log('handleFooterMenuSelect: ' + index)
      },
    },

  }
</script>

<style lang="scss">
  @import "@/assets/styles/index.scss";
</style>
