<template>
	<div class="right_content_footer">
        <div class="right_content_footer_padding"></div>
        <div class="right_content_footer_content">
        <span class="right_content_footer_span_left">©MASS Corp. All rights reserved.</span>
        <div class="right_content_footer_span_right">
            <div class="footer_padding"></div>
            <a class="footer_btn" href="https://massbloom.com/agreement" target="_blank">Terms of use</a>
            <div class="footer_padding"></div>
            <a class="footer_btn" href="https://www.massbloom.com/privacy" target="_blank">Privacy Policy</a>
            <div class="footer_padding"></div>
            <a class="footer_btn" href="https://www.massbloom.com/contactUs" target="_blank">Contact Us</a>
            <!-- <div class="footer_padding"></div>
            <a class="footer_btn" href="https://massbloom.com/aboutus" target="_blank">Credits</a> -->
        </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'HomeFooter',
    data() {
        //这里存放数据",
        return {


        };
    },
    components: {  },

    methods: {
      termsOfUseClick(){
        console.log('termsOfUseClick')

      },
      privacyPolicyClick() {
        console.log('privacyPolicyClick')
      },
      contactUsClick() {
        console.log('contactUsClick')
      },
      creditsClick(){
        console.log('creditsClick')
      },
    },
}

</script>

<style lang="scss">

</style>
