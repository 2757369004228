import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/backStage/Home'
import Layout from '@/views/backStage/Layout'
import Login from '@/views/backStage/Login'

// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/myContents',
    component: Layout,
    redirect: '/myContents',
    children: [
      {
        path: '/myContents',
        name: 'myContents',
        component: () => import('@/views/backStage/MyContents/ContentList'),
        meta: { title: 'My Contents', icon: 'ordered-list', keepAlive: false }
      },
      {
        path: '/sales',
        name: 'sales',
        component: () => import('@/views/backStage/Sales/Sales'),
        meta: { title: 'Sales', icon: 'alert', keepAlive: false}
      },
      {
        path: '/withdraw',
        name: 'withdraw',
        component: () => import('@/views/backStage/Withdraw/Withdraw'),
        meta: { title: 'Withdraw', icon: 'alert', keepAlive: false}
      },
      {
        path: '/insight',
        name: 'insight',
        component: () => import('@/views/backStage/Insight/InsightList'),
        meta: { title: 'Insight', icon: 'alert', keepAlive: false}
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/backStage/Settings'),
        meta: { title: 'Settings', icon: 'alert', keepAlive: false}
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
